.thumb_img img {
	width: 100%;
    height: 200px;
    object-fit: cover;
}
.btn_add_adrs {
	width: 100%;
    height: 100%;
	min-height: 329px;
    display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px #e0e0e0 dashed;
    border-radius: 0.35em;
    color: #e0e0e0 !important;
    font-size: 20px;
	transition: all 0.3s ease;
}
.btn_add_adrs span {
	display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 0.15em;
    width: 48px;
    height: 48px;
    color: #fff;
    margin-bottom: 10px;
	transition: all 0.3s ease;
}
.total_amt li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style: none;
	border-bottom: 1px solid #e0e0e0;
	padding: 10px 0;
}
.total_amt li:last-child {
	border-bottom: none;
	font-weight: 600;
}
.total_amt li span {
	flex-basis: 25%;
	font-weight: 600;
	text-align: right;
}
#fabs-examples .card-img-top {
	width: 100%;
	height: 270px;
	object-fit: cover;
}
.single-product-spec {
	max-width: 50%;
	padding: 0;
}
.single-product-spec li {
	display: flex;
	align-items: center;
	padding: 0 0 10px;
	list-style: none;
}
.single-product-spec li span {
	flex-basis: 30%;
	font-weight: 600;
}
.prod-card {
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease;
}
.prod-card-edit {
	position: absolute !important;
    top: 20px;
    right: 20px;
	background-color: #fff !important;
    color: #6c757d  !important;
	transition: all 0.3s ease-in;
}
.prod-card-delete {
	position: absolute !important;
    top: 70px;
    right: 20px;
    background-color: #fff !important;
    color: #6c757d !important;
	transition: all 0.3s ease-in;
}
.add_cart {
	border: none;
    outline: none;
    position: absolute !important;
    top: 10px;
    right: 10px;
}
.users-edit .nav-tabs .nav-link.active {
	border-bottom: 2px solid #2f8be6;
}
.view_dtls {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9;
	padding: 0 5px;
	border: none;
	border-radius: 0px;
	display: flex;
	align-items: center;
	box-shadow: none !important;
	background-color: #d0d0d0;
}
.prod_list {
	list-style-position: inside;
}
@media screen and (max-width: 767px) {
	.custom_table table {
		border: 0;
	}
	
	.custom_table table caption {
		font-size: 1.3em;
	}
	.custom_table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.custom_table tr {
		border-bottom: 3px solid #ddd;
		display: block;
		margin-bottom: .625em;
		background-color: #f9f9f9;
	}
	.custom_table td {
		display: block;
		font-size: .8em;
		text-align: right;
		padding: 15px 10px !important;
		border-bottom: 1px solid #E0E0E0;
    	border-top: 0;
	  }
	.custom_table td::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}
	table td:last-child {
		border-bottom: 0;
	}
	.prod_list {
		list-style-position: inside;
		display: block;
		text-align: left;
		padding-top: 25px !important;
	}
	.prod_list::before,
	.prod_list::after {
		display: table;
		content: "";
		clear: both;
	}
	.prod_list li {
		padding-bottom: 10px;
	}
}

/* Custom CSS by Sourav */
.block-hover:hover{
	/* background-color: #f2f2f2; */
	/* transform: scale(1.0125); */
	cursor: pointer;
	/* transform: scale(1.0125); */
	/* box-shadow: 5px 5px #DBDEE5; */
}

.product-block:hover{
    background-color: #DBDEE5;
}
.zoom:hover{
	transform: scale(1.025);
}


.loader,
  .loader:before,
  .loader:after {
    background: #718183;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .loader {
    color: #718183;
    text-indent: -9999em;
    margin: auto auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }